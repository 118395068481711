<template>
  <div class="">
    <v-row
      dense
      justify="space-between"
      align="center"
      class="gradients grey--text text--lighten-2 rounded pa-2 mx-1"
    >
      <span>{{ $t("license information") }}</span>
      <!-- <AddCurrency @message="showSuccess" @error="showError"/> -->
    </v-row>
    <v-row>
      <v-row
        v-show="pleaseWait"
        class="fill-height mt-7"
        align-content="center"
        justify="center"
      >
        <v-col class="text-subtitle-1 text-center" cols="12">
          loading... please wait
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            :color="$store.state.primaryColor"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>

      <v-col cols="12">
        <v-list class="pa-0">
          <div v-for="detail in details" :key="detail.id">
            <v-list-item class="justify-space-between">
              <v-col class="caption">{{ detail.title }}</v-col>

              <v-col
                v-if="detail.title == 'Days Left'"
                class="caption font-weight-bold -right"
              >
                <countdown :left-time="DateDifference(detail.value)">
                  <template v-slot:process="time">
                    <span class="caption font-weight-bold">
                      <span
                        v-if="MonthFormat(time.timeObj.d) != 0"
                        class="py-1 px-2 grey lighten-3"
                        >{{ MonthFormat(time.timeObj.d) }}
                      </span>

                      <span
                        v-if="time.timeObj.h != 0"
                        class="py-1 px-2 grey lighten-3"
                        >{{ time.timeObj.h }} h</span
                      >
                      <span
                        v-if="time.timeObj.m != 0"
                        class="py-1 px-2 grey lighten-3"
                        >{{ time.timeObj.m }} min</span
                      >
                      <span class="py-1 px-2 grey lighten-3"
                        >{{ time.timeObj.s }} sec</span
                      >
                    </span>
                  </template>
                  <template v-slot:finish>
                    <span
                      class="subtitle-2 rounded pa-2 pink lighten-5 red--text text--darken-3"
                      >{{ $t("time Expired for the day") }}</span
                    >
                  </template>
                </countdown>
              </v-col>
              <v-col v-else class="caption font-weight-bold -right">
                <span class="py-1 px-2 grey lighten-3">{{
                  detail.value
                }}</span></v-col
              >
            </v-list-item>
            <v-divider></v-divider>
          </div>
          <v-list-item class="justify-space-between">
            <v-col class="caption">{{ $t("renew subscription") }}</v-col>
            <v-col>
              <v-btn
                :disabled="renew == false"
                dark
                small
                :color="$store.state.primaryColor"
                @click="renewLicense()"
              >
                <v-icon left small>mdi-autorenew</v-icon>
                <span class="caption">Renew</span>
              </v-btn>
            </v-col>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackMessage"
      dark
      color="primary lighten-4 primary--text text--darken-3"
    >
      <v-icon large left color="primary">info</v-icon>
      <span>{{ message }}</span>
      <template v-slot:action="{ attr }">
        <v-btn
          v-bind="attr"
          icon
          color="primary darken-3"
          @click="snackMessage = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="snackerror"
      color="red lighten-4 red--text text--darken-3"
    >
      <v-icon left color="error darken-3">fas fa-bug</v-icon>
      <span>{{ errorMessage }}</span>
      <template v-slot:action="{ attr }">
        <v-btn
          v-bind="attr"
          icon
          color="error darken-3"
          @click="snackerror = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import db from "@/plugins/fb";
import { format, differenceInDays } from "date-fns";
// import AddCurrency from '@/components/shop/settings/currency/AddCurrency'

export default {
  components: {
    // AddCurrency,
  },
  data() {
    return {
      dialog: false,

      pleaseWait: true,
      licenses: [],
      renew: "",
      icons: [
        { icon: "edit", route: "edit" },
        { icon: "delete", route: "" },
      ],

      snackMessage: false,
      snackerror: false,
      message: "",
      errorMessage: "",
      loading: true,

      remainingTime: 31475256942,
    };
  },

  created() {
    this.currentUser = this.$store.state.currentUser;
    this.getLicense();
  },
  computed: {
    details() {
      return [
        { title: this.$t("license code"), value: "" },
        { title: this.$t("license package"), value: "" },
        { title: this.$t("maximum number of branches"), value: "" },
        { title: this.$t("total number of users"), value: "" },
        { title: this.$t("storage capacity"), value: "" },
        // { title:'Storage used',                 value:'' },
        { title: this.$t("expiry date"), value: "" },
        { title: this.$t("days left"), value: "" },
        { title: this.$t("payment status"), value: "" },
      ];
    },
  },

  methods: {
    getLicense() {
      this.pleaseWait = true;
      this.licenses = [];
      db.collection("licenses")
        .where("ownerId", "==", this.currentUser)
        .get()
        .then((snapshot) => {
          snapshot.forEach((snaps) => {
            db.collection("packages")
              .doc(snaps.data().packageId)
              .get()
              .then((snap) => {
                this.licenses.push({
                  id: snaps.id,
                  ...snaps.data(),
                  ...snap.data(),
                });
                this.pleaseWait = false;
                this.getLicenseValue();
              });
          });
        });
    },

    getLicenseValue() {
      this.licenses.forEach((val) => {
        this.details[0].value = val.id.toUpperCase();
        this.details[1].value = val.name.toUpperCase();
        this.details[2].value = val.description.branches;
        this.details[3].value = val.description.users;
        this.details[4].value = val.description.storage;
        this.details[5].value = this.DateFormat(val.expiryDate);
        this.details[6].value = val.expiryDate;
        //    this.DateDifference(val.expiryDate);
        this.details[7].value = val.isValid;
      });
    },

    DateFormat(val) {
      return format(val.toDate(), "E dd-MMMM-yyyy hh:mm:ss");
    },

    MonthFormat(days) {
      if (days > 30) {
        var months = Math.floor(days / 31);
        var mrmd = days / 31 - Math.floor(months);
        var d = Math.floor(mrmd * 31);
        return months + " mths " + d + " d";
      } else {
        return days + " d";
      }
    },

    DateDifference(val) {
      var today = new Date();
      var expiry = val.toDate();
      this.remainingTime = expiry - today;
      // use remaining time to determine if to enable and disable a button

      //get number of left days using differenceInDays from date fns
      let difInDays = differenceInDays(val.toDate(), new Date());

      //set button disbaled true or false
      if (difInDays < 30) {
        this.renew = true;
      } else {
        this.renew = false;
      }
      return this.remainingTime;
    },

    renewLicense() {
      this.$router.push({
        name: "updateLicense",
        params: { licenseId: this.licenses[0].id },
      });
    },

    showSuccess(event) {
      this.message = event;
      this.snackMessage = true;
      this.fetchCurrency();
    },

    showError(event) {
      this.errorMessage = event;
      this.snackerror = true;
    },
  },
};
</script>
